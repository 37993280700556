import {useEffect, useState} from "react";
import {Conversation} from "../types/Conversation";
import {Link, useLocation} from "react-router-dom";


export default function ConversationList() {
    const [conversations, setConversations] = useState<Conversation[]>([])
    const [pageNumber, setPageNumber] = useState(1)
    const location = useLocation();

    // Load conversations from the API
    const loadConversations = async () => {
        // Calculate the limit parameter based on the current page
        // The current page is passed as a query parameter
        // The limit parameter is used to limit the number of conversations returned by the API
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const urlParams = new URLSearchParams(window.location.search);
        const page = parseInt(urlParams.get('page') || '1');
        setPageNumber(page);
        const limit = 10;
        const offset = (page - 1) * limit;

        const base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${base_url}/conversation?limit=${limit}&skip=${offset}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            }
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        let data = await response.json();
        setConversations(data);
    }

    // Load conversations when the component is first rendered
    useEffect(() => {
        loadConversations();
    }, [location.search]);

    return (
        <div>
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Conversations
                </h2>
            </div>

            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    ID
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    User Email
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Namespace
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    # Messages
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Created At
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Sentiment
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Errors
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Actions</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {conversations.map((conversation) => (
                                <tr key={conversation.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        {conversation.id}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{conversation.user_email}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{conversation.namespace}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{conversation.messages.length}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(conversation.created_at).toLocaleString()}</td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {conversation.messages.reduce((acc, message) => acc + (message.rating ? message.rating : 0), 0) >= 0 ? (
                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                Positive
                                            </span>
                                        ) : (
                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                Negative
                                            </span>
                                        )}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {conversation.had_error ? (
                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                Error
                                            </span>
                                        ) : (
                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                No Errors
                                            </span>
                                        )}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <a href={`/conversations/${conversation.id}`}
                                           className="text-indigo-600 hover:text-indigo-900">
                                            View
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <div className="flex justify-between mt-4">
                            <Link to={`?page=${pageNumber - 1}`}
                                  className={`text-gray-500 ${pageNumber === 1 ? 'pointer-events-none' : ''}`}>
                                Previous
                            </Link>
                            <Link to={`?page=${pageNumber + 1}`}
                                  className={`text-gray-500`}>
                                Next
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
