import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Conversation} from "../types/Conversation";
import {Chat} from "../components/Chat";


export default function ConversationDetails() {
    // Get conversation ID from the URL
    const {id} = useParams();
    const [conversation, setConversation] = useState<Conversation | null>(null);

    // Load conversation from the API
    const loadConversation = async () => {
        const base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${base_url}/conversation/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            }
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        let data = await response.json();
        setConversation(data);
    }

    // Load conversation when the component is first rendered
    useEffect(() => {
        loadConversation();
    }, []);

    // Use chat component to display messages in the conversation
    return (
        <div>
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Conversation Details
                </h2>
            </div>

            {conversation && (
                <div>
                    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-6 sm:px-6">
                            <h3 className="text-base font-semibold leading-7 text-gray-900">Conversation Information</h3>
                        </div>
                        <div className="border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">Conversation ID</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{conversation.id}</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">First message TS</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {conversation.messages[0].created_at}
                                    </dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">Last message TS</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {conversation.messages[conversation.messages.length - 1].created_at}
                                    </dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">User</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{conversation.user_email} (ID {conversation.user_id})</dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">Sentiment</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {conversation.messages.reduce((acc, message) => acc + (message.rating ? message.rating : 0), 0) >= 0 ? (
                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                Positief
                                            </span>
                                        ) : (
                                            <span
                                                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                                Negatief
                                            </span>
                                        )}
                                    </dd>
                                </div>
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-900">Errors</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {conversation.had_error ? conversation.error_message : null}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    <Chat messages={conversation.messages} onUpvote={() => {
                    }} onDownvote={() => {
                    }} allowVote={false}/>
                </div>
            )}
        </div>
    );
}
