import React, { FC } from 'react';
import { Message } from '../types/Message';
import Markdown from 'react-markdown';

import './ChatMessage.css';

interface ChatMessageProps {
    message: Message
    allowVote?: boolean;
    onUpvote: (message: Message) => void;
    onDownvote: (message: Message) => void;
}

export const ChatMessage: FC<ChatMessageProps> = ({ message , onUpvote, onDownvote, allowVote}) => {
    const upvote = () => {
        onUpvote(message);
    }
    const downvote = () => {
        onDownvote(message);
    }
    return (
        <div className={`flex flex-col ${message.role === "assistant" ? "items-start" : "items-end"}`}>
            < div
                className={`${message.role === "assistant" ? "bg-green-600 text-zinc-50" : "bg-blue-500 text-white"} rounded-2xl px-3 py-2 max-w-[67%] whitespace-pre-wrap chat-message`}>
                <Markdown>{message.content}</Markdown>
            </div>
            {(message.rating && !allowVote) || (message.rating !== 0 && message.rating !== undefined && allowVote) ?
                (
                    message.rating >= 0 ?
                            (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">Positief</span>) :
                            (<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">Negatief</span>)
                )
                : null
            }

            {message.role === "assistant" && allowVote && (message.rating === 0 || message.rating === undefined) ?
               <div>
                    <button className="UpvoteMessage" onClick={upvote}>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title/>
                            <g id="Complete">
                            <g id="thumbs-up">
                            <path d="M7.3,11.4,10.1,3a.6.6,0,0,1,.8-.3l1,.5a2.6,2.6,0,0,1,1.4,2.3V9.4h6.4a2,2,0,0,1,1.9,2.5l-2,8a2,2,0,0,1-1.9,1.5H4.3a2,2,0,0,1-2-2v-6a2,2,0,0,1,2-2h3v10" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                            </g>
                        </svg>
                    </button>
                    <button className="DownvoteMessage" onClick={downvote}>
                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title/>
                            <g id="Complete">
                            <g id="thumbs-down">
                            <path d="M7.3,12.6,10.1,21a.6.6,0,0,0,.8.3l1-.5a2.6,2.6,0,0,0,1.4-2.3V14.6h6.4a2,2,0,0,0,1.9-2.5l-2-8a2,2,0,0,0-1.9-1.5H4.3a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2h3V2.6" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </g>
                            </g>
                        </svg>
                    </button>
               </div>
                : null
            }
        </div>
    )
}
