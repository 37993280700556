import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from './routes/root';
import Chat from './routes/chat';
import ConversationList from "./routes/ConversationList";
import ConversationDetails from "./routes/ConversationDetails";
import Dashboard from "./routes/Dashboard";

async function getUserInfo () {
    // Call API https://iam.dewaele.com/api/user and get user information
    const response = await fetch("https://iam.dewaele.com/api/user", {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (!response.ok) {
        // redirect to login page
        window.location.href = "https://iam.dewaele.com/authentication/login?social_partner=google&success_url=https://mia-mgmt.dewaele.com&failure_url=https://iam.dewaele.com/authentication/login";
    }
    let userInfo = await response.json();
    console.log(userInfo);
    return {id: userInfo.id, email: userInfo.email, full_name: userInfo.collaborator.display_name, avatar_url: userInfo.collaborator.picture };
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        loader: getUserInfo,
        children: [
            {
                path: "/",
                element: <Dashboard />,
                loader: getUserInfo
            },
            {
                path: "/chat",
                element: <Chat />,
                loader: getUserInfo
            },
            {
                path: "/conversations",
                children: [
                    {
                        path: "/conversations",
                        loader: getUserInfo,
                        element: <ConversationList />
                    },
                    {
                        path: "/conversations/:id",
                        loader: getUserInfo,
                        element: <ConversationDetails />
                    }
                ]
            }

        ]
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
