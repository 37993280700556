import React, {useEffect, useState} from 'react';
import { useLoaderData } from 'react-router-dom';
import UserInfo from "../types/UserInfo";

export default function Dashboard() {
    const user = useLoaderData() as UserInfo;
    const [conversationCount, setConversationCount] = useState<number>(0);

    const loadConversationStatistics = async () => {
        const base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${base_url}/conversation/stats`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            }
        });
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        let data = await response.json();
        setConversationCount(data.conversation_count);
    }

    useEffect(() => {
        loadConversationStatistics();
    }, []);

    return (
        <div>
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Mia Dashboard
                </h2>
            </div>

            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                            <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                                <dt className="text-base leading-7 text-gray-600">Conversations</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                    {conversationCount}
                                </dd>
                            </div>
                    </dl>
                </div>
            </div>
        </div>
    );
}
