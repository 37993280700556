import {useEffect, useState} from 'react';
import { Chat } from '../components/Chat';
import { Message } from '../types/Message';
import { ChatInput } from '../components/ChatInput';
import UserInfo from "../types/UserInfo";
import {Link, useLoaderData, useNavigate} from "react-router-dom";

export default function ChatInterface() {
    const [messages, setMessages] = useState<Message[]>([]);
    const [conversation, setConversation] = useState<number>(0);
    const userInfo = useLoaderData() as UserInfo;
    const [namespace, setNamespace] = useState<string>("");
    const navigate = useNavigate();

    const setInitialMessages = async () => {
        let initialMessage = {
            role: "assistant",
            content: "Hallo, hoe kan ik je helpen?"
        } as Message;
        setMessages([initialMessage]);
    }

    const createConversation = async () => {
        // Get base url from env variable
        const base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch( `${base_url}/conversation`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            },
            body: JSON.stringify({
                "namespace": namespace,
                "user_id": userInfo.id,
                "user_email": userInfo.email
            })
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }
        let data = await response.json();
        console.log("Data: ", data.id);
        setConversation(data.id);
        return data.id;
    }

    const upVote = async (message: Message) => {
        console.log("Upvoting message: ", message);
        message.rating = 1;
        // Update message in the state using setMessages
        setMessages((messages) => {
            const updatedMessages = messages.map((msg) => {
                if (msg.id === message.id) {
                    return message;
                }
                return msg;
            });
            return updatedMessages;
        })

        const base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${base_url}/conversation/${conversation}/message/${message.id}/rate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            },
            body: JSON.stringify({
                "rating": 1
            })
        })
    }

    const downVote = async (message: Message) => {
        console.log("Downvoting message: ", message);
        message.rating = -1
        // Update message in the state using setMessages
        setMessages((messages) => {
            const updatedMessages = messages.map((msg) => {
                if (msg.id === message.id) {
                    return message;
                }
                return msg;
            });
            return updatedMessages;
        })

        let base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${base_url}/conversation/${conversation}/message/${message.id}/rate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            },
            body: JSON.stringify({
                "rating": -1
            })
        })
    }

    const handleSend = async (message: Message) => {
        const updatedMessages = [...messages, message];
        setMessages(updatedMessages);

        let conversation_id = conversation;
        if (conversation_id === 0) {
            console.log("Creating conversation...");
            conversation_id = await createConversation();
        }
        const base_url = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${base_url}/conversation/${conversation_id}/chat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'basic ' + btoa('dewaele:mia-test-8855')
            },
            body: JSON.stringify(updatedMessages)
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        const data = response.body;
        if (!data) {
            return;
        }

        const reader = data.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let isFirst = true;

        while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            const chunkValue = decoder.decode(value);
            let messages = chunkValue.split('\n');
            for (let i = 0; i < messages.length; i++) {
                let message = messages[i];
                if (message.length === 0) {
                    continue;
                }
                const msg = JSON.parse(message);
                console.log(msg)

                if (isFirst) {
                    isFirst = false;
                    setMessages((messages) => [
                        ...messages,
                        {
                            id: msg.message_id,
                            user_id: 0,
                            user_email: "",
                            role: "assistant",
                            content: msg.content
                        }
                    ]);
                } else {
                    setMessages((messages) => {
                        const lastMessage = messages[messages.length - 1];
                        const updatedMessage = {
                            ...lastMessage,
                            content: lastMessage.content + msg.content
                        };
                        return [...messages.slice(0, -1), updatedMessage];
                    });
                }
            }
        }
    }

    useEffect(() => {
        setInitialMessages();
    }, []);

    return (
        <>
            <div className="min-w-0 flex-1 mb-10">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Chat Interface
                </h2>
            </div>

            {namespace === "" ? (
                <span className="isolate inline-flex rounded-md shadow-sm mt-5 mb-5">
                    <button
                        type="button"
                        onClick={() => setNamespace("external")}
                        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    >Externe Chat</button>
                    <button
                        type="button"
                        onClick={() => setNamespace("internal")}
                        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                    >Interne Dewaele Chat</button>
                </span>
            ) : null}

            {namespace != "" ? (
                <div>
                    <span className="mb-5 rounded-md bg-blue-50 p-4">
                        {namespace === "external" ? "Je chat nu als externe gebruiker met Mia." : "Je chat nu als interne Dewaele medewerker met Mia."}
                        <button onClick={() => navigate(0)} className="ml-2 text-blue-500">Verander van chat</button>
                    </span>
                    <div className="mb-3 mt-10">
                    <Chat messages={messages} onUpvote={upVote} onDownvote={downVote} allowVote={true} />
                </div>
                <ChatInput onSend={handleSend}/>
                </div>
            ) : null }
        </>
    )
}
