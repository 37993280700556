import { FC } from "react";
import { Message } from "../types/Message";
import { ChatMessage } from "./ChatMessage";

interface ChatProps {
    messages: Message[];
    allowVote?: boolean;
    onUpvote: (message: Message) => void;
    onDownvote: (message: Message) => void;
}

export const Chat: FC<ChatProps> = ({ messages , onUpvote, onDownvote, allowVote}) => {
    return (
        <>
            <div className="flex flex-col rounded-lg px-2 sm:p-4 sm:border border-neutral-300">
                {messages.map((message, index) => {
                    return (
                        <div className="my-1 sm-my-1.5">
                            <ChatMessage key={index} message={message} allowVote={allowVote} onUpvote={onUpvote} onDownvote={onDownvote}/>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
