import { FC, useState } from "react";
import { Message } from "../types/Message";
import { FaArrowCircleUp } from "react-icons/fa";

interface Props {
    onSend: (message: Message) => void;
}

export const ChatInput: FC<Props> = ({ onSend }) => {

    const [content, setContent] = useState<string>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    }

    const handleSend = () => {
        if (!content) {
            return;
        }
        onSend({ id: 0, role: "user", content})
        setContent("");
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    }

    return (
        <div className="relative">
            <input className="min-h-[44px] rounded-lg pl-4 pr-12 py-2 w-full focus:outline-none focus:ring-1 focus:ring-neutral-300 border-2 border-neutral-200"
                type="text" onChange={handleChange} onKeyDown={handleKeyDown} value={content} placeholder="Type your chatmessage here" />
            <button onClick={() => handleSend()}>
                <FaArrowCircleUp className="absolute right-2 bottom-2 h-8 w-8 hover:cursor-pointer rounded-full p-1 bg-blue-600 text-white hover:opacity-80" />
            </button>
        </div>
    )
}
